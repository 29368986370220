body {
    background-color: #262626;
    color: white;
}

.jumbotron {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(23, 65, 214) 0%, rgba(0,212,255,1) 50%, rgb(23, 65, 214) 100%);
}

.jumbotron > * {
    color:black;
}

.features {
    display: table;
    margin: 20px auto;
}

.studio-btn {
    text-decoration: none;
    color: black;
}

.features-events {
    margin-top: 15px;
    background: linear-gradient(90deg, rgb(23, 65, 214) 0%, rgba(0,212,255,1) 50%, rgb(23, 65, 214) 100%);
    border: 2px solid black;
    border-radius: 5px;
}

.features-events > * {
    color: black;
}

#titleContainer {
    text-align: center;
}

#title {
    width:250px;
}

canvas {
    cursor: crosshair;
}

.tools {
    height: 45px;
}

.tool-btn {
    border: 1px solid black;
}

.about {
    background: linear-gradient(90deg, rgb(128, 128, 128) 0%, rgb(255, 255, 255), rgb(128, 128, 128) 100%);

}